@import 'antd/es/style/core/index.less';
@import 'antd/es/button/style/index.less';
@import 'antd/es/input/style/index.less';
@import 'antd/es/grid/style/index.less';
@import 'antd/es/progress/style/index.less';
@import 'antd/es/typography/style/index.less';
@import 'antd/es/form/style/index.less';
@import 'antd/es/checkbox/style/index.less';
@import 'antd/es/alert/style/index.less';
@import 'antd/es/steps/style/index.less';
@import 'antd/es/upload/style/index.less';
@import 'antd/es/select/style/index.less';
@import 'antd/es/timeline/style/index.less';
@import 'antd/es/collapse/style/index.less';
@import 'antd/es/space/style/index.less';
@import 'antd/es/image/style/index.less';
@import 'antd/es/tabs/style/index.less';
@import 'antd/es/carousel/style/index.less';
@import 'antd/es/time-picker/style/index.less';
@import 'antd/es/date-picker/style/index.less';
@import 'antd/es/menu/style/index.less';
@import 'antd/es/layout/style/index.less';
@import 'antd/es/switch/style/index.less';
@import 'antd/es/tag/style/index.less';
@import 'antd/es/table/style/index.less';
@import 'antd/es/pagination/style/index.less';
@import 'antd/es/dropdown/style/index.less';
@import 'antd/es/modal/style/index.less';
@import 'antd/es/spin/style/index.less';
@import 'antd/es/tooltip/style/index.less';
@import 'antd/es/avatar/style/index.less';
@import 'antd/es/radio/style/index.less';
@import 'antd/es/popover/style/index.less';
@import 'antd/es/notification/style/index.less';
@import 'antd/es/divider/style/index.less';
@import 'antd/es/badge/style/index.less';

@basic-height: 40px;
@border-radius-base: 4px;
@border-radius-sm: 4px;
@border-radius-small: 4px;
@btn-border-radius-base: 20px;
@btn-border-radius-sm: 20px;
@btn-danger-bg: #ff4141;
@btn-danger-border: #ff4141;
@btn-danger-color: #ffffff;
@btn-default-bg: #ffffff;
@divider-border-color: #e3e4e4;
@btn-default-border: #9e9e9e;
@btn-default-color: #606060;
@btn-default-ghost-bg: transparent;
@btn-default-ghost-border: #606060;
@btn-default-ghost-color: #606060;
@btn-font-size-base: 14px;
@btn-font-size-sm: 14px;
@btn-font-weight: 400;
@btn-height-base: 40px;
@btn-height-sm: 32px;
@btn-line-height-base: 1.7;
@btn-line-height-sm: 1.25;
@btn-padding-horizontal-base: 24px;
@btn-padding-horizontal-small: 8px;
@btn-success-bg: #68d656;
@btn-success-border: #68d656;
@btn-success-color: #ffffff;
@default-bg: #eff3f5;
@error-color: #ff4141;
@error-color-active: #ff6161;
@error-color-deprecated-border: #ff6161;
@error-color-deprecated-dg: #ff6161;
@error-color-hover: #ff6161;
@error-color-outline: #ff6161;
@font-family: 'Cabin', sans-serif;
@font-size-base: 14px;
@font-size-large: 24px;
@font-size-small: 12px;
@heading-1-size: 40px;
@heading-2-size: 32px;
@heading-3-size: 24px;
@heading-4-size: 16px;
@heading-5-size: 14px;
@heading-6-size: 12px;
@info-color: #007fff;
@info-color-active: #379bff;
@info-color-deprecated-border: #379bff;
@info-color-deprecated-dg: #379bff;
@info-color-hover: #379bff;
@info-color-outline: #379bff;
@input-disabled-color: #6060604d;
@input-height-base: 40px;
@input-hover-border-color: #fca625;
@input-placeholder-color: #9e9e9e;
@link-color: none;
@link-decoration: none;
@link-hover-color: none;
@link-hover-decoration: none;
@primary-color: #fcc425;
@primary-color-active: #ffd150;
@primary-color-deprecated-border: #ffd150;
@primary-color-deprecated-dg: #ffd150;
@primary-color-hover: #ffd150;
@primary-color-outline: #ffd150;
@progress-default-color: #9e9e9e;
@success-color: #68d656;
@success-color-active: #81d972;
@success-color-deprecated-border: #81d972;
@success-color-deprecated-dg: #81d972;
@success-color-hover: #81d972;
@success-color-outline: #81d972;
@text-color-primary: #0d0d0d;
@text-color-secondary: #9e9e9e;
@warning-color: #f6911a;
@warning-color-active: #ffb156;
@warning-color-deprecated-border: #ffb156;
@warning-color-deprecated-dg: #ffb156;
@warning-color-hover: #ffb156;
@warning-color-outline: #ffb156;

/*breakpoints*/
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-sm: 576px;
@screen-sm-min: @screen-sm;
@screen-md: 768px;
@screen-md-min: @screen-md;
@screen-lg: 992px;
@screen-lg-min: @screen-lg;
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

//Alerts
@alert-success-border-color: fade(@success-color, 10%);
@alert-success-bg-color: fade(@success-color, 10%);
@alert-success-icon-color: @success-color;
@alert-info-border-color: fade(@info-color, 10%);
@alert-info-bg-color: fade(@info-color, 10%);
@alert-info-icon-color: @info-color;
@alert-warning-border-color: fade(@warning-color, 10%);
@alert-warning-bg-color: fade(@warning-color, 10%);
@alert-warning-icon-color: @warning-color;
@alert-error-border-color: fade(@error-color, 10%);
@alert-error-bg-color: fade(@error-color, 10%);
@alert-error-icon-color: @error-color;
// Swithch
@switch-height: 20px;
@switch-sm-height: 15px;
@switch-min-width: 40px;
@switch-sm-min-width: 28px;
/*magic*/

// Divider
@divider-text-padding: 0;
@divider-color: @divider-border-color;

// Table
@table-body-sort-bg: transparent;
@table-selected-row-bg: transparent;
//Modal
@modal-header-border-width: 0;
@modal-footer-border-width: 0;

//Avatar
@avatar-font-size-base: 16px;

// Radio
@radio-size: 16px;
@radio-top: 0.2em;
@radio-border-width: 2px;
@radio-dot-size: @radio-size - 8px;
@radio-dot-color: #0078f9;
@radio-dot-disabled-color: fade(@black, 20%);
@radio-solid-checked-color: @component-background;
